exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-index-copy-jsx": () => import("./../../../src/pages/index copy.jsx" /* webpackChunkName: "component---src-pages-index-copy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-development-index-jsx": () => import("./../../../src/pages/mobile-development/index.jsx" /* webpackChunkName: "component---src-pages-mobile-development-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-terms-of-use-index-jsx": () => import("./../../../src/pages/terms-of-use/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-jsx" */),
  "component---src-pages-thanks-copy-1-index-jsx": () => import("./../../../src/pages/Thanks (copy 1)/index.jsx" /* webpackChunkName: "component---src-pages-thanks-copy-1-index-jsx" */),
  "component---src-pages-thanks-index-jsx": () => import("./../../../src/pages/Thanks/index.jsx" /* webpackChunkName: "component---src-pages-thanks-index-jsx" */),
  "component---src-pages-web-development-index-jsx": () => import("./../../../src/pages/web-development/index.jsx" /* webpackChunkName: "component---src-pages-web-development-index-jsx" */)
}

